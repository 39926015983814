<template>
    <v-main>
        <v-container fill-height>
            <v-row>
                <v-col class="col-sm-6 mx-auto">
                    <v-card class="col-sm-12 mx-auto rounded-xl" outlined elevation="2">
                        <!-- <v-row>
                         <v-col>
                            <v-img :width="200"  cover lazy-src="https://sv1.picz.in.th/images/2023/05/25/Fj1wmP.png" src="https://sv1.picz.in.th/images/2023/05/25/Fj1wmP.png"></v-img>
                            <v-row>
                                <v-card-title>Send Notification System</v-card-title>
                            </v-row>
                           
                         </v-col>
                         <v-col>
                            <v-img :width="200"  cover lazy-src="https://sv1.picz.in.th/images/2023/05/25/Fj9wER.png" src="https://sv1.picz.in.th/images/2023/05/25/Fj9wER.png"></v-img>
                            <v-row>
                                <v-card-title >Evaluate360 System</v-card-title>
                            </v-row>
                            
                         </v-col>
                            
                            
                        </v-row>-->

                        <v-divider></v-divider>
                        <v-card-text>
                            <v-form @submit.prevent="login()">
                                <h1 style="text-align: center;" color="info">Login System Stone</h1>
                                <br />
                                <v-text-field v-model="username" label="ชื่อผู้ใช้งาน" required prepend-inner-icon="mdi-account-circle" outlined dense clearable></v-text-field>
                                <v-text-field v-model="password" label="รหัสผ่าน" required :type="showPassword ? 'text' : 'password'" prepend-inner-icon="mdi-lock" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPassword = !showPassword" outlined dense clearable></v-text-field>
                                <v-select v-model="menuSystem" :items="itemSystem" item-value="itemSystemId" :item-text="(item) => item.itemSystemName" label="Select System" outlined dense></v-select>
                                <v-btn class="col-sm-12" type="submit" color="info">เข้าสู่ระบบ</v-btn>
                            </v-form>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>

<script>
import APIClient from '../core/BaseAPIService'
import router from "../router"

const QUOTATION = "?s=quotation"

export default {
    name: "LoginView",
    created() {
        this.menuSystem = parseInt(localStorage.getItem("menuSystem"))
    },
    data() {
        return {
            username: "",
            password: "",
            showPassword: false,
            itemSystem: [
                {
                    itemSystemId: 0,
                    itemSystemName: "HR360 System"
                },
                {
                  "itemSystemId": 1,
                  "itemSystemName": "Send Notification System"
                }
                // {
                //   "itemSystemId": 2,
                //   "itemSystemName": "QuotationReport System"
                // }
                ],
            menuSystem: 0,
        }
    },
    beforeCreate() {
        let currentURL = location.href

        if (currentURL.includes(QUOTATION)) {
            localStorage.setItem("menuSystem", 2)
        } else {
            localStorage.setItem("menuSystem", 0)
        }
    },
    methods: {
        async login() {
            const payload = {
                username: this.username,
                password: this.password,
                menuSystem: this.menuSystem
            }

            await APIClient()
                .post("/login", payload)
                .then(res => {
                    console.log(res)

                    let user = {
                        userId: res.data.userId,
                        username: res.data.username,
                        fullname: res.data.fullname,
                        levelId: res.data.levelID,
                        departmentID: res.data.departmentID,
                        sendNotimanualHome: res.data.sendNotimanualHome,
                        sendNotimanualHistory: res.data.sendNotimanualHistory,
                        sendNotimanualHistoryAll: res.data.sendNotimanualHistoryAll,
                        sendNotiAutoSetEvent: res.data.sendNotiAutoSetEvent,
                        sendNotiAutoHistory: res.data.sendNotiAutoHistory,
                        hr360YourTeam: res.data.hr360YourTeam,
                        hr360OtherTeam: res.data.hr360OtherTeam,
                        hr360OBossTeam: res.data.hr360OBossTeam,
                        hr360OSumScoreFinal: res.data.hr360OSumScoreFinal,
                        sendNotiManualByUser: res.data.sendNotiManualByUser,
                        sendNotiManualByUserHistory: res.data.sendNotiManualByUserHistory,
                        hr360OSumCommentFinalYour: res.data.hr360OSumCommentFinalYour,
                        hr360OSumCommentFinalOther: res.data.hr360OSumCommentFinalOther,
                        hr360OSumCommentFinalBoss: res.data.hr360OSumCommentFinalBoss,
                        quotationReport: res.data.quotationReport,
                        menuSystem: payload.menuSystem
                    }
                    // ถ้าเลือก select Notification System แต่ไม่มีสิทธิเห็นเมนูนี้
                    if (user.menuSystem == 1 && user.sendNotimanualHome == 0) {
                        alert("คุณไม่มีสิทธิเข้าระบบ Notification System")
                    }
                    // ถ้าเลือก select QuotationReport System แต่ไม่มีสิทธิเห็นเมนูนี้ (ไม่ใช่เซลล์)
                    else if (user.menuSystem == 2 && user.quotationReport == 0) {
                        alert("คุณไม่มีสิทธิเข้าระบบ QuotationReport System")
                    } else {
                        sessionStorage.setItem("Access-Token", res.data.token)
                        sessionStorage.setItem("user", JSON.stringify(user))
                        router.push({ name: "MainViewWelcome" })
                    }
                })
                .catch(err => {
                    console.log(err.response)
                    alert("Username or Password Wrong!")
                })
        }
    }
}
</script>
<style scoped>
.v-main {
    height: 100%;
    padding: 0px 0px 0px 0px;
    background: radial-gradient(circle, #90a0ac 0%, #546978 100%) !important;
}
</style>
