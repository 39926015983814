import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '@/views/LoginView'
// import HistorySendNoti from '@/views/HistorySendNoti'

Vue.use(VueRouter)

const DEFAULT_TITLE = process.env.VUE_APP_TITLE || "SendNoti & Evaluate360 System";

const routes = [
  {
    path: '/historySendNoti',
    name: 'historySendNoti',
    component: () => import(/* webpackChunkName: "about" */ '../views/HistorySendNoti.vue'),
    meta: { title: "ประวัติการส่ง", requiredLogin: true }
  },
  {
    path: '/historySendNotiAll',
    name: 'historySendNotiAll',
    component: () => import(/* webpackChunkName: "about" */ '../views/HistorySendNotiAll.vue'),
    meta: { title: "ประวัติการส่ง", requiredLogin: true }
  },
  {
    path: '/setSendNotiAuto',
    name: 'setSendNotiAuto',
    component: () => import(/* webpackChunkName: "about" */ '../views/SetSendNotiAuto.vue'),
    meta: { title: "ระบบ Auto", requiredLogin: true }
  },
  {
    path: '/historySendNotiAuto',
    name: 'historySendNotiAuto',
    component: () => import(/* webpackChunkName: "about" */ '../views/HistorySendNotiAuto.vue'),
    meta: { title: "ประวัติการส่ง Auto", requiredLogin: true }
  },
  {
    path: '/historySendNotiAutoSummary',
    name: 'historySendNotiAutoSummary',
    component: () => import(/* webpackChunkName: "about" */ '../views/HistorySendNotiAutoSummary.vue'),
    meta: { title: "ประวัติการส่ง Auto Summary", requiredLogin: true }
  },
  {
    path: '/hrEvaluate360YourTeam',
    name: 'hrEvaluate360YourTeam',
    component: () => import(/* webpackChunkName: "about" */ '../views/HrEvaluate360YourTeam.vue'),
    meta: { title: "HR360 ทีมเรา", requiredLogin: true }
  },
  {
    path: '/hrEvaluate360OtherTeam',
    name: 'hrEvaluate360OtherTeam',
    component: () => import(/* webpackChunkName: "about" */ '../views/HrEvaluate360OtherTeam.vue'),
    meta: { title: "HR360 ทีมอื่น", requiredLogin: true }
  },
  {
    path: '/hrEvaluate360BossTeam',
    name: 'hrEvaluate360BossTeam',
    component: () => import(/* webpackChunkName: "about" */ '../views/hrEvaluate360BossTeam.vue'),
    meta: { title: "HR360 สำหรับผู้บริหาร", requiredLogin: true }
  },
  {
    path: '/hrEvaluate360SumScoreFinal',
    name: 'hrEvaluate360SumScoreFinal',
    component: () => import(/* webpackChunkName: "about" */ '../views/hrEvaluate360SumScoreFinal.vue'),
    meta: { title: "HR360 สรุปคะแนน", requiredLogin: true }
  },
  {
    path: '/hrEvaluate360SumCommentYourTeamFinal',
    name: 'hrEvaluate360SumCommentYourTeamFinal',
    component: () => import(/* webpackChunkName: "about" */ '../views/hrEvaluate360SumCommentYourTeamFinal.vue'),
    meta: { title: "HR360 ประวัติจากทีมเรา", requiredLogin: true }
  },
  {
    path: '/hrEvaluate360SumCommentOtherTeamFinal',
    name: 'hrEvaluate360SumCommentOtherTeamFinal',
    component: () => import(/* webpackChunkName: "about" */ '../views/hrEvaluate360SumCommentOtherTeamFinal.vue'),
    meta: { title: "HR360 ประวัติจากทีมอื่น", requiredLogin: true }
  },
  {
    path: '/hrEvaluate360SumCommentBossTeamFinal',
    name: 'hrEvaluate360SumCommentBossTeamFinal',
    component: () => import(/* webpackChunkName: "about" */ '../views/hrEvaluate360SumCommentBossTeamFinal.vue'),
    meta: { title: "HR360 ประวัติจากผู้บริหาร", requiredLogin: true }
  },
  {
    path: '/sendNotiByUser',
    name: 'sendNotiByUser',
    component: () => import(/* webpackChunkName: "about" */ '../views/sendNotiByUser.vue'),
    meta: { title: "ส่งข้อความรายคน", requiredLogin: true }
  },
  {
    path: '/HistorySendNotiByUser',
    name: 'HistorySendNotiByUser',
    component: () => import(/* webpackChunkName: "about" */ '../views/HistorySendNotiByUser.vue'),
    meta: { title: "ประวัติการส่งรายคน", requiredLogin: true }
  },
  {
    path: '/QuotationReport',
    name: 'QuotationReport',
    component: () => import(/* webpackChunkName: "about" */ '../views/QuotationReport.vue'),
    meta: { title: "สร้างใบเสนอราคา", requiredLogin: true }
  },
  {
    path: '/MainViewWelcome',
    name: 'MainViewWelcome',
    component: () => import(/* webpackChunkName: "about" */ '../views/MainViewWelcome.vue'),
    meta: { title: "Welcome", requiredLogin: true }
  },

  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/main',
    name: 'main',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/MainView.vue'),
    meta: { title: "ส่งข้อความ", requiredLogin: true }
  },
  {
    path: '/',
    name: 'LoginView',
    component: LoginView,
    meta: { title: "เข้าสู่ระบบ" }
  },
  {
    path: '*', //ถ้าพิมพ์ url มั่วๆ ก็ให้เด้งไปที่หน้า 404 อะไรก็ว่าไป
    name: 'HomeView',
    component: HomeView,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// ทุกครั้งก่อนที่จะเปลี่ยนหน้า
router.beforeEach((to, from, next) => {
  // ดึงข้อมูลผู้ใช้งานจาก session storage
  let user = JSON.parse(sessionStorage.getItem("user"))

  // เช็คว่าหน้าที่จะไปต้องทำการเข้าสู่ระบบก่อนหรือไม่
  if (to.meta.requiredLogin) {
    // ถ้าต้องเข้าสู่ระบบก่อน ก็เช็คว่า user is null ถ้า null ให้พาไปหน้าล็อคอิน ถ้าไม่ null ก็ผ่านเลยไป
    if (user === undefined || user === null) {
      next({
        path: "/",
        params: { nextUrl: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    // ถ้าไม่ต้องเข้าสู่ระบบก่อน ก็เช็คว่า user is null ถ้า null ก็ผ่านเลยไป แต่ถ้าไม่ null ก็พาไปหน้าประวัติ
    if (user !== null) {
      next({
        path: "/MainViewWelcome",
        params: { nextUrl: to.fullPath },
      });
    } else {
      next();
    }
  }
})

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title != undefined ? to.meta.title + " - " + DEFAULT_TITLE : DEFAULT_TITLE;
  });
});

export default router
